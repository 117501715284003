<template>
    <div class="vx-row panel_all_member">
        <div class="vx-col w-full mb-base">

		<vs-row>
			<vs-col vs-xs="12" vs-sm="12" vs-lg="11">
				<div class="grid-layout-container alignment-block">
					<vs-row>
						<vs-col vs-w="3" vs-xs="12" vs-sm="6">
							<div class="btn_special"  v-bind:style="getBtnEffet('essai')" v-on:click="btnSwitchData('essai',true)">
								<span>Essais</span>
								<b>{{ btn_nb.essai }}</b>
							</div>
							<br/>
						</vs-col>
						<vs-col vs-w="3" vs-xs="12" vs-sm="6">
							<div class="btn_special" v-bind:style="getBtnEffet('actif')" v-on:click="btnSwitchData('actif',true)">
								<span>Actifs</span>
								<b>{{ btn_nb.actif }}</b>
							</div>
							<br/>
						</vs-col>
						<vs-col vs-w="3" vs-xs="12" vs-sm="6">
							<div class="btn_special" v-bind:style="getBtnEffet('suspendu')" v-on:click="btnSwitchData('suspendu',true)">
								<span>Suspendus</span>
								<b>{{ btn_nb.suspendu }}</b>
							</div>
							<br/>
						</vs-col>
						<vs-col vs-w="3" vs-xs="12" vs-sm="6">
							<div class="btn_special" v-bind:style="getBtnEffet('inactif')" v-on:click="btnSwitchData('inactif',true)">
								<span>Inactifs</span>
								<b>{{ btn_nb.inactif }}</b>
							</div>
							<br/>
						</vs-col>
					</vs-row>
				</div>
			</vs-col>
			<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="12" vs-lg="1">
				<router-link to="/admin/mbrstatistique">
					<vs-icon icon="assessment" size="85px" style="margin-top:-15px;" class="cursor"></vs-icon>
				</router-link>
			</vs-col>
		</vs-row>


			<vs-prompt title="Export Membre" class="export-options" @cancel="clearFields" @accept="exportToExcel" accept-text="Exporter" @close="clearFields" :active.sync="activePrompt">
				<vs-input v-model="fileName" placeholder="Nom du fichier" class="w-full" />
				<v-select v-model="selectedFormat" :options="formats" class="my-4" />
				<div class="flex">
					<span class="mr-4">Espacement:</span>
					<vs-switch v-model="cellAutoWidth">Espacement</vs-switch>
				</div>
			</vs-prompt>

			<!-- table -->
			<vs-table multiple v-model="selected" pagination max-items="20" search :data="members" noDataText="Aucun résultat">
				
				<!-- header template -->
				<template slot="header">
						<div class="dropdown-button-container">
							<vs-button class="btnx nowrap" type="filled" v-on:click="mbrAllSelect()">{{ getBtnSelectName() }}</vs-button>
							<vs-button class="btnx2 nowrap" type="filled" v-on:click="mbrSelect()" v-bind:style="getBtnSelectEffet()" >{{ selected.length }}</vs-button>
							<vs-dropdown>
								<vs-button class="btn-drop cursor" type="filled" icon="menu"></vs-button>
								<vs-dropdown-menu>
									<template v-if="!isPageCampagne()">
										<vs-dropdown-item v-on:click="addMember()">Ajouter essai</vs-dropdown-item>
										<vs-dropdown-item v-on:click="StatusMember()" divider>Modifier etat</vs-dropdown-item>
										<vs-dropdown-item v-on:click="TypeMember()">Modifier type</vs-dropdown-item>
										<template v-if="admin">
											<vs-dropdown-item v-on:click="RGPDMember()">Archiver RGPD</vs-dropdown-item>
										</template>
											
										<vs-dropdown-item divider v-on:click="SendSMS()">Envoyer SMS</vs-dropdown-item>
										<vs-dropdown-item v-on:click="SendEmail()">Envoyer Email</vs-dropdown-item>
										<!--<vs-dropdown-item>Envoyer email</vs-dropdown-item>-->

										<template v-if="admin">
											<vs-dropdown-item divider @click="activePrompt=true">Export</vs-dropdown-item>
										</template>
									</template>
									<template v-if="isPageCampagne()">
										<vs-dropdown-item v-on:click="SendSMS()">Envoyer SMS</vs-dropdown-item>
										<vs-dropdown-item v-on:click="SendEmail()">Envoyer Email</vs-dropdown-item>
									</template>

								</vs-dropdown-menu> 
							</vs-dropdown>
						</div>
				</template>
				<!-- header table -->
				<template slot="thead">
					<!--<vs-th sort-key="uid" class="f_center">Photo</vs-th>-->
					<vs-th sort-key="first_name">Prénom Nom</vs-th>
					<vs-th sort-key="phone">Téléphone</vs-th>
					<vs-th sort-key="city">Ville</vs-th>
					<vs-th sort-key="credit">Crédit/Utilisé</vs-th>
					<vs-th sort-key="dif">Créance</vs-th>
					<vs-th sort-key="last_booking">Der. Visite</vs-th>
					<!--<vs-th sort-key="uid">Statut</vs-th>-->
				</template>

				<!--Body table-->
				<template slot-scope="{data}">
					<vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
						<!--<vs-td :data="data[indextr].uid">
							<div class="con-img ml-3" v-on:click="selectMbr(data[indextr].uid)">
								<img key="onlineImg" :src="getUrlAvatar(data[indextr])" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
							</div>
						</vs-td>-->
						<vs-td :data="data[indextr].first_name">
							<span v-on:click="selectMbr(data[indextr].uid)">{{ StrCapital(data[indextr].first_name+' '+data[indextr].last_name) }}</span>
						</vs-td>
						<vs-td :data="data[indextr].phone">
							<span v-on:click="selectMbr(data[indextr].uid)">{{ getPhoneClean(data[indextr].phone) }}</span>
						</vs-td>
						<vs-td :data="data[indextr].city">
							<span v-on:click="selectMbr(data[indextr].uid)">{{ data[indextr].city }}</span>
						</vs-td>
						<vs-td :data="data[indextr].uid">
							<span v-on:click="selectMbr(data[indextr].uid)">{{ data[indextr].credit+'/'+data[indextr].credit_util }}</span>
						</vs-td>
						<vs-td>
							<span v-on:click="selectMbr(data[indextr].uid)">{{ data[indextr].dif }} {{ devise.symbol }}</span>
						</vs-td>
						<vs-td :data="data[indextr].last_booking">
							<template v-if="verifTime(data[indextr].last_booking)">
								<span v-on:click="selectMbr(data[indextr].uid)">{{ printDate(data[indextr].last_booking) }}</span>
							</template>
						</vs-td>
						<!--<vs-td :data="data[indextr].uid">
							<span v-on:click="selectMbr(data[indextr].uid)">{{ getStatus( data[indextr] ) }}</span>
						</vs-td>-->


					</vs-tr>
				</template>
			</vs-table>

			<br/><br/>
		</div>

		<AddMember ref="addMember" />
		<StatusMember ref="StatusMember" />
		<TypeMember ref="TypeMember" />
		<RGPDMember ref="RGPDMember" />
	</div>
</template>

<style lang="scss">
.panel_all_member
{
	.dropdown-button-container {
	margin-right:5px;
	display: flex;
	align-items: center;

	.btnx {
		border-radius: 5px 0px 0px 5px;
	}
	.btnx2 {
		border-left: 1px solid rgba(255, 255, 255, .2);	
		border-radius: 0px 0px 0px 0px;
	}

	.btn-drop {
		border-radius: 0px 5px 5px 0px;
		border-left: 1px solid rgba(255, 255, 255, .2);
	}
	}
	.btn_special
	{
		font-weight: bold;
		margin-right:5px;
		padding:20px;
		padding-left:40px;
		padding-right:40px;
		color:white;
		border-radius: 5px;
		font-size:1.2em;
		cursor:pointer;

		border:2px;
		span{
			text-align:left;
		}
		b{
			float:right;
		}
	}
	th .vs-checkbox-small 
	{
		display:none;
	}

	@media only screen and (max-width: 550px)
	{
		//Probléme de responsivité si le menu recherche reste
		.vs-table--search
		{
			display:none;
		}
	}
}
</style>

<script>
import vSelect from 'vue-select'
import Fdate from '@/assets/utils/fdate'
import Utils from '@/assets/utils/utils'
import Phone from '@/assets/utils/phone'
import Credit from '@/assets/utils/credit'
import Member from '@/database/models/member'
import Booking from '@/database/models/booking'
import Channel from '@/database/models/channel';
import ChannelLink from '@/database/models/channel_link';

import autoRefresh from '@/assets/utils/autoRefresh'


import AddMember from '@/components/member/add.vue'
import StatusMember from '@/components/member/status.vue'
import TypeMember from '@/components/member/type.vue'
import RGPDMember from '@/components/member/rgpd.vue'

export default {
	components: {
		vSelect,
		AddMember,
		StatusMember,
		TypeMember,
		RGPDMember
	},
    data(){
        return{
					admin:false,

					//table
					selected: [],
					all_member:[],
					members : [],
					buffer_mbr :[],
					buffer_mbr_etat: false,

					//btn nb
					btn_nb:{
						essai:0,
						actif:0,
						suspendu:0,
						inactif:0,
					},
					btn_select:'none',
					btn_buffer:[],

					//export
					fileName: '',
					formats:['xlsx', 'csv', 'txt'],
					cellAutoWidth: true,
					selectedFormat: 'xlsx',
					headerTitle: [],
					headerVal: [],
					activePrompt: false,

					devise: {}

        }
	},
	//----------------------------------------------------
	// Lors de l'appelle de la page
	//----------------------------------------------------
	mounted:function()
	{
		this.$srvApi.Req('get', 'public/currency')
		.then(( rep )=>{
				this.devise = rep.data;
		})
		//autoRefresh
		autoRefresh.SetCallback(()=>{
			this.manuelRunLoad();
		})
		//load
		autoRefresh.refresh()
	},
	watch:{
        $route (){
			this.manuelRunLoad();
        }
    },
    methods:{

		manuelRunLoad()
		{
			this.load(()=>
			{
				if(this.btn_select !='none')
					this.btnSwitchData(this.btn_select)
			})
			this.RoleAdmin();
		},

		printDate( date )
		{
			return Fdate.getFullDateMin( date );
		},
		verifTime( date )
		{
			date = new Date(date);
			if( date.getTime() == 0 )
				return false;
			return true;
		},

		load( callback )
		{
			this.btn_nb.essai    = 0
			this.btn_nb.actif    = 0
			this.btn_nb.suspendu = 0
			this.btn_nb.inactif  = 0


			Member.getTabAllMemory(async (input_tab)=>
			{
				let list_channel = await Channel.AwaitGetAllMemory();

				//Nettoyer imput pour éviter que le champs recherche cherche dans les autres champs !
				let tab = [];
				for( var p=0; p<input_tab.length; p++)
				{
					let uid = input_tab[p].uid;
					let tab_credit  = await Credit.getCreditFiltre( uid, 'global' );
					let consumption = await Credit.getMemberConsumption( uid );

					let channel     = await ChannelLink.AwaitGetAllMemoryKey(uid);
					let channel_name = '';
					if(channel != undefined)
					for( let i in list_channel)
					for( let n in channel)
						if(channel[n].channel == i)
							channel_name = list_channel[i].name;

					//get creance
					let creance = await Credit.getMemberCreance(uid);

					//Booking dernierre seance
					let booking_final = (new Date()).setTime(0);
					let booking = await Booking.AwaitGetAllMemoryKey(uid);
					if(booking)
					{
						let tab_book = [];
						for( let ib in booking )
							tab_book.push( new Date(booking[ib].date) );
						tab_book.sort(function(a, b)
						{
							let time_a = a.getTime();
							let time_b = b.getTime();
							return time_b-time_a;
						});
						if(tab_book[0])
							booking_final = tab_book[0];
					}

					//Final DATA
					tab.push({
						uid          : uid,
						first_name   : input_tab[p].first_name,
						last_name    : input_tab[p].last_name,
						phone        : input_tab[p].phone,
						phone2       : this.getPhoneClean(input_tab[p].phone).replace(/ /g,''),
						email        : input_tab[p].email,
						address      : input_tab[p].address,
						cp           : Utils.PostalCodeFormat( input_tab[p].postal_code ),
						city         : input_tab[p].city,
						credit       : tab_credit.credit,
						credit_util  : tab_credit.used,
						dif          : creance.toFixed(this.devise.nb_decimal),
						last_booking : booking_final,
						active       : input_tab[p].active,
						type         : input_tab[p].type,
						vest         : input_tab[p].vest,
						birthday     : Fdate.getFullDateMin(input_tab[p].birthday),
						gender       : input_tab[p].gender,
						consumption  : consumption.toFixed(this.devise.nb_decimal),
						csms         : input_tab[p].csms,
						newsletter   : input_tab[p].newsletter,
						mollie       : input_tab[p].mollie,
						memberAt     : Fdate.getFullDateMin(input_tab[p].memberAt),
						createAt     : Fdate.getFullDateMin(input_tab[p].createAt),
						channel      : channel_name,
					})
				}

				//set all data
				this.all_member = tab;
				this.members = tab;

				//set export format
				let buffer = tab[0];
				for( const cname in buffer )
				if(cname != 'uid')
				if(cname != 'phone2')
				if(cname != 'memberAt')
				{
					this.headerTitle.push(Utils.StrCapital(cname));
					this.headerVal.push(cname);
				}


				//btn set pour filtre
				for(var i=0; i< tab.length; i++)
				{
					var buf = tab[i];

					switch( Member.getTypeSpec( buf ))
					{
						case 'essai':
							this.btn_nb.essai++;
						break;
						case 'actif':
							this.btn_nb.actif++;
						break;
						case'suspendu':
							this.btn_nb.suspendu++;
						break;
						case 'inactif':
							this.btn_nb.inactif++;
						break;
					}
				}

				if(callback)
					callback()
			})
		},

		//----------------------------------------------------
		//table base fonction
		//----------------------------------------------------
		getStatus( mbr )
		{
			return Member.getAuthName(mbr)+' / '+Member.getEtatName(mbr);
		},
		getUrlAvatar( member )
		{
			return this.$srvApi.buildUrl('studio/member/'+member.uid+'/photo')
		},
		getPhoneClean( phone )
		{
			return Phone.getLocalPhone( phone );
		},
		StrCapital( msg )
		{
			return Utils.StrCapital(msg);
		},
		selectMbr( uid )
		{
			this.$router.push('/admin/member/'+uid);
		},
		getDate( d )
		{
			return Fdate.getFullDateMin(d)
		},
		getBtnEffet( name )
		{
			if(this.btn_select != name)
				return { "background-color":"white",'color':Member.color[name] };
			return { "background" : 'linear-gradient(120deg, '+Member.color[name]+','+Member.color[name]+','+Member.color[name]+','+Member.color['f'+name]+')' };
		},
		getBtnSelectEffet()
		{
			if(this.buffer_mbr_etat)
				return { 'background-color':'#493cc9 !important' };
		},
		getBtnSelectName()
		{
			if(this.selected.length>0)
				return 'Désélectionner';
			return 'Tout séléctionner';
		},
		RoleAdmin()
        {
            this.admin = false;
                  //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            Member.getMemory(uid, (mbr)=>
            {
                this.admin = mbr.type.toLowerCase() == "a";
            });
        },


		//----------------------------------------------------
		//Fonction de selection de tout les membres ou deselection
		//----------------------------------------------------
		mbrAllSelect()
		{
			//tout selectionné
			if(this.selected.length==0)
				return this.selected = this.members;
			if(this.selected.length!=0)
				return this.selected = [];
		},
		//----------------------------------------------------
		//Fonction de selection et deselection de membre
		//----------------------------------------------------
		mbrSelect()
		{
			//Desaffiché que les selectionné
			if( this.buffer_mbr_etat == true)
			{
				this.buffer_mbr_etat = false;
				this.members = this.buffer_mbr;
			}
			else
			//afficher les selectionné
			{
				this.buffer_mbr_etat = true;
				this.buffer_mbr = this.members;
				this.members = this.selected;
			}
		},
		//----------------------------------------------------
		//Remplace les data par un résultat de filtre bouton
		//----------------------------------------------------
		btnSwitchData( btn_select, press=false )
		{
			//mode press
			if(press)
			if( this.btn_select == btn_select)
			{
				this.btn_select = 'none'
				this.members = this.all_member
				return
			}

			//set mode button
			this.btn_select = btn_select
			//desactive bouton
			this.buffer_mbr_etat = false;

			//si pas de filtre
			if( this.btn_select == 'none')
			{
				this.members = this.all_member
				return
			}

			//Filtre
			this.members = []
			for(var i=0; i< this.all_member.length; i++)
			{
				var buf = this.all_member[i];
				var t = Member.getTypeSpec( buf );

				if(this.btn_select == t )
				switch(t)
				{
					case 'essai':
						this.members.push(buf);
					break;
					case 'actif':
						this.members.push(buf);
					break;
					case'suspendu':
						this.members.push(buf);
					break;
					case 'inactif':
						this.members.push(buf);
					break;
				}
			}
		},



		//----------------------------------------------------
		//export excel
		//----------------------------------------------------
		clearFields ()
		{
			this.filename = ''
			this.cellAutoWidth = true
			this.selectedFormat = 'xlsx'
		},
		exportToExcel ()
		{
			let root = this;

			import('@/assets/utils/Export2Excel')
			.then(excel => {
				//get data selected ou all member
				let list = root.selected;
				if(list.length==0)
					list = root.members;


				//Parse correction
				let final_export = [];
				list = JSON.parse(JSON.stringify(list));
				for( var i in list )
				{
					let buffer = JSON.parse(JSON.stringify(list[i]));
					buffer.type   = Member.getAuthName( buffer );
					buffer.active = Member.getEtatName( buffer );
					buffer.gender = Member.getGender( buffer );
					delete buffer.phone2;
					final_export.push( buffer );
				}


				//prépare data
				const data = this.formatJson(root.headerVal, final_export);

				//export
				excel.export_json_to_excel(
				{
					header: root.headerTitle,
					data,
					filename : root.fileName,
					autoWidth: root.cellAutoWidth,
					bookType : root.selectedFormat
				});
				root.clearFields();
			});
		},
		formatJson (filterVal, jsonData)
		{
			let data = jsonData.map(v => filterVal.map(function(j) 
			{
				//Correction de champs ICI
				//if(j == 'uid')
				//	v[j] = undefined;
				return v[j];
			}));

			//BUG! enlever les duplications !!
			filterVal = Utils.removeDuplicate(filterVal);
			//Fin
			return data;
		},




		//-----------------------------------------
		//Action de groupe
		//-----------------------------------------
		addMember(){
			this.$refs.addMember.openPopup(()=>
			{
				this.load()
			})
		},
		StatusMember(){
			this.$refs.StatusMember.openPopup( this.selected, ()=>
			{
				this.load()
			})
		},
		TypeMember(){
			this.$refs.TypeMember.openPopup( this.selected, ()=>
			{
				this.load()
			})
		},
		RGPDMember(){
			this.$refs.RGPDMember.openPopup(this.selected, ()=>
			{
                alert('Archivage RGPD effectué !')
				this.load()
			})
        },


		SendSMS(){
			window.api_selected = this.selected;
			this.$router.push('/admin/sms/groupe');
		},
		isPageCampagne()
		{
			return this.$router.currentRoute.path != "/admin/member"
		},

		SendEmail(){
			window.api_selected = this.selected;
			this.$router.push('/admin/email/groupe');
		},
	}
};
</script>