<template>
    <vs-popup class="memberType_popup" title="RGPD archivage" :active.sync="popupActive">
        
        <template v-if="uid_list.length==0">
            <p>Aucun membre choisie !</p>
        </template>
        <template v-else>
            <div class="w-3/3 px-5">
                <h3>Archivage RGPD <template v-if="uid_list.length>1">de {{ uid_list.length }} utilisateurs</template></h3><br/>
                <br/>
                <p>Attention, voulez-vous placer ce contact en base d'exclusion RGPD ?<br/>Vous ne pourrez plus revenir en arrière</p>
            </div>
            <vs-divider/>

            <template v-if="admin">
                <vs-row>
                    <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                        <div></div>
                        <vs-button color="success" type="filled" v-on:click="upd()">Je confirme</vs-button>
                    </vs-col>
                </vs-row>
            </template>
            <template v-else>
                <p>Opération uniquement Admin.</p>
            </template>

        </template>
    </vs-popup>
</template>

<style lang="scss">
.memberType_popup
{

}
</style>

<script>
import Member  from '@/database/models/member'

export default {
    components:{
	},
    props: {
    },
    data(){
        return{
            popupActive:false,
            callback:null,

            uid_list : [],

            admin:false
        }
    },
    watch:{
        $route (){
        },
    },
    beforeMount:function(){
        this.RoleAdmin();
    },
    methods:{
        openPopup( uid_list, callback )
        {
            this.uid_list    = uid_list
            this.callback    = null
            this.popupActive = true

            if(callback)
                this.callback = callback
        },
        upd()
        {
            let compte = 0;
            let nb = 0;

            let end = ()=>{
                compte++

                if(compte >= nb )
                if(this.callback)
                    this.callback()
            }

            //update member
            for( var i=0; i<this.uid_list.length; i++)
            {
                if(this.uid_list[i].type != 'a' )
                {
                    let uid = this.uid_list[i].uid;
                    nb++

                    //Delete local BDD
                    Member.keyDelete( uid );

                    //mis en RGPD
                    this.$srvApi.Req('put', 'studio/member/'+uid+'/rgpd')
                    .then(end)
                    .catch((err)=>{
                        alert(err)
                        end()
                    })
                }
                else
                    alert('Les admins ne peuvent pas être archivés !')
            }

            //fermeture popup
            this.popupActive = false
        },


        RoleAdmin()
        {
            this.admin = false;
                  //Chargement info user
            let uid = this.$srvApi.getLoginUserUid();
            Member.getMemory(uid, (mbr)=>
            {
                this.admin = mbr.type.toLowerCase() == "a";
            });
        },
    }
}

</script>